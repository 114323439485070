import {
    useState,
    useRef,
    useEffect,
    useCallback,
    useMemo
} from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Container,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Checkbox,
    Button,
    Typography,
    Avatar,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Breadcrumbs
} from '@material-ui/core';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import moment from 'moment';
import {
    useGet,
    usePost,
    usePut,
    useHttpDelete
} from '../request';
import getInitials from '../utils/getInitials';
import { uploadURL } from '../enviroment';
import GroupModalAddMember from '../components/group/GroupModalAddMember';
import GroupListMembers from '../components/group/GroupListMembers';
import { useConfirm } from '../components/confirm';
import { useAlert } from '../components/alert';

const useStyles = makeStyles({
    wrap: {
        flexDirection: 'row',
        display: 'flex',
        paddingBottom: 16
    },
    wrapStatus: {
        flexDirection: 'row',
        display: 'flex',
        paddingBottom: 16,
        alignItems: 'center'
    },
    wrapEnd: {
        flexDirection: 'row',
        display: 'flex',
    },
    label: {
        width: 200,
        fontSize: 16,
        fontFamily: 'Roboto',
    },
    text: {
        fontSize: 16,
        fontFamily: 'Roboto'
    },
});

const Group = () => {
    const { id } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [file, setFile] = useState(null);
    const [group, setGroup] = useState(null);
    const [members, setMembers] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [services, setServices] = useState([]);
    const [currentServices, setCurrentServices] = useState([]);
    const styles = useStyles();
    const get = useGet();
    const post = usePost();
    const put = usePut();
    const httpDelete = useHttpDelete();
    const alert = useAlert();
    const confirm = useConfirm();
    const navigate = useNavigate();
    const [listMembersReload, setListMembersReload] = useState(false);

    const handleFile = (event) => {
        setFile(event.target.files[0]);
    }

    const loadServices = () => {
        get('admin/services')
        .then((response) => {
          if (response.result === 'success') {
            setAllServices(response.services);
          }
        })
        .catch((e) => console.log(e));
    };

    const loadGroups = () => {
        get(`admin/groups/${id}`)
        .then((response) => {
          if (response.result === 'success') {
            setGroup(response.group);
            setMembers(response.members);
            setServices(response.services);
            setListMembersReload(true);
          }
        })
        .catch((e) => console.log(e));
    };

    const addMember = (userId) => {
        const requestData = {
            groups_id: id,
            user_id: userId
        };

        post('admin/group-member', requestData)
        .then((response) => {
            if (response.result === 'error') {
                alert({
                    status: 'error',
                    message: response.message
                });
                return;
            }
            loadGroups();
        })
        .catch((e) => console.log(e));
    };

    const saveServices = () => {
        const requestData = {
            groups_id: id,
            services_id_list: currentServices.reduce((list, item) => ({ ...list, [item.id]: item.checked }), {})
        };
        put('admin/group/services', requestData)
        .then(() => {
            setIsEdit(false);
            loadGroups();
        })
        .catch((e) => console.log(e));
    };

    const handleSwithStatus = () => {
        const requestData = {
            ...group,
            isActived: group.isActived ? 0 : 1
        };

        post(`admin/groups/${id}`, requestData)
        .then((response) => {
            if (response.result === 'success') {
                loadGroups();
            }
        })
        .catch((e) => console.log(e));
    };

    const handleChangeService = (service) => {
        setCurrentServices(currentServices.map((item) => {
            if (item.id === service.id) {
                return { ...item, checked: !item.checked }
            }
            return item;
        }));
    };

    useEffect(() => {
        loadServices();
        loadGroups();
    }, []);

    useEffect(() => {
        setCurrentServices(allServices.map((service) => ({
            ...service,
            checked: !!services.find((elem) => elem.services_id === service.id),
        })))
    }, [allServices, services]);

    if (!group) {
        return (
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Helmet>
                <title>Group | Material Kit</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box sx={{ py: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
                            <Box style={{ marginTop: 3, marginRight: 10 }}>
                                <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
                                    Back
                                </Button>
                            </Box>
                            <Typography color="textPrimary" variant="h4" component="h1">
                                {group.name}
                            </Typography>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <RouterLink to={`/app/group-import/${id}`}>
                                    <Button color="primary" variant="contained">
                                        Import
                                    </Button>
                                </RouterLink>
                                <Box sx={{ ml: 1 }}>
                                    <RouterLink to={`/app/group-export/${id}`}>
                                        <Button color="primary" variant="contained">
                                            Export
                                        </Button>
                                    </RouterLink>
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <RouterLink to={`/app/group-expiration-date/${id}`}>
                                        <Button color="primary" variant="contained">
                                            Set expiration date
                                        </Button>
                                    </RouterLink>
                                </Box>
                                <Box sx={{ ml: 2 }}>
                                    <RouterLink to={`/app/group/edit/${id}`}>
                                        <Button color="primary" variant="contained">
                                            Edit
                                        </Button>
                                    </RouterLink>
                                </Box>
                            </Box>
                        </Box>
                        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: 12 }}>
                            <RouterLink underline="hover" color="inherit" to="/app/groups">
                                Groups
                            </RouterLink>
                            <p>{group.name}</p>
                        </Breadcrumbs>
                    </Box>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Info" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                        <Divider />
                        <CardContent>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Broker:
                                </div>
                                <div className={styles.text}>
                                    {group.broker_name || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Address:
                                </div>
                                { (group.city || group.state || group.postal_code) ? (
                                    <div className={styles.text}>
                                        {group.city ? `${group.city}` : ''}
                                        {group.state ? ` ${group.state}` : ''}
                                        {group.postal_code ? ` ${group.postal_code}` : ''}
                                    </div>
                                ) : (
                                    <div className={styles.text}>
                                        ---
                                    </div>
                                )}
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Phone:
                                </div>
                                <div className={styles.text}>
                                    {group.phone || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    General contact:
                                </div>
                                <div className={styles.text}>
                                    {group.general_contact || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Email:
                                </div>
                                <div className={styles.text}>
                                    {group.email || '---'}
                                </div>
                            </div>
                            <div className={styles.wrap}>
                                <div className={styles.label}>
                                    Notes:
                                </div>
                                <div className={styles.text}>
                                    {group.comments || '---'}
                                </div>
                            </div>
                            {group.expirtaion_date && (
                                <div className={styles.wrap}>
                                    <div className={styles.label}>
                                        Expirtaion date:
                                    </div>
                                    <div className={styles.text}>
                                        {moment(group.expirtaion_date).format('DD.MM.YYYY')}
                                    </div>
                                </div>
                            )}
                            <div className={styles.wrapStatus}>
                                <div className={styles.label}>
                                    Status:
                                </div>
                                <div className={styles.text}>
                                    {!group.isActived ? 'Paused' : 'Actived'}
                                </div>
                                <Button onClick={handleSwithStatus} sx={{ ml: 2 }} color="primary" variant="contained">
                                    {group.isActived ? 'Pause' : 'Activate'}
                                </Button>
                            </div>
                            <div className={styles.wrapEnd}>
                                <div className={styles.label}>
                                    Members count:
                                </div>
                                <div className={styles.text}>
                                    {group.members_count || 0}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card sx={{ mb: 2 }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          px: 2,
                          py: 1
                        }}
                        >
                            <Typography color="textPrimary" variant="h5" component="h2">
                                Networks
                            </Typography>
                            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                                <Box sx={{ ml: 2 }}>
                                    { isEdit ? (
                                        <Button color="success" variant="contained" onClick={saveServices}>
                                            Save
                                        </Button>
                                    ) : (
                                        <Button color="primary" variant="contained" onClick={() => setIsEdit(true)}>
                                            Edit
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <CardContent>
                            { currentServices.length > 0 ? currentServices.map((service) => (
                                <Box key={uuidv4()} sx={{ alignItems: 'center', display: 'flex' }}>
                                    <Checkbox disabled={!isEdit} checked={service.checked} onChange={() => handleChangeService(service)} />
                                    <Typography color="textPrimary" variant="body1">
                                        {service.name}
                                    </Typography>
                                </Box>
                            )) : (
                                <Typography color="textPrimary" variant="body1" component="p" sx={{ alignItems: 'center', display: 'flex' }}>
                                    No found
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                    <Card sx={{ mb: 2 }}>
                        <CardHeader title="Members" titleTypographyProps={{ variant: 'h5' }} component="h2" />
                        <Divider />
                        { group.id && (
                            <GroupListMembers
                              groupId={group.id}
                              reload={listMembersReload}
                              setReload={setListMembersReload}
                              button={(
                                <GroupModalAddMember addMember={addMember}>
                                    {({ openModal }) => (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={openModal}
                                        >
                                            Add member
                                        </Button>
                                    )}
                                </GroupModalAddMember>
                              )}
                            />
                        )}
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Group;
